<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-body>
                <wit-table
                    mode="server"
                    :fields="columns"
                    :request-function="requestFunction"
                    striped
                    hide-per-page-selector
                    ref="tokenTable"
                    :default-per-page="10"
                >
                    <template v-slot:cell(owner)="{item}">{{
                        `${item.owner.name}${item.owner.email ? ` (${item.owner.email})` : ''}`
                    }}</template>
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <RemoveAction v-if="!isViewer" :removeResource="removeModal" :resource="item" />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <RemoveRecordModal
            v-model="showRemoveModal"
            :removeElement="removeRecord"
            :recordId="removeRecordId"
            @close="removeRecordId = null"
        >
            <div slot="error">
                <Feedback :state="removeError.state" :invalid="removeError.message" />
            </div>
        </RemoveRecordModal>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import Loading from '@/components/loading.vue'
import DetailsAction from '@/components/Actions/DetailsAction.vue'
import Feedback from '@/components/Feedback.vue'
import ReauthorizeAction from '@/components/Actions/ReauthorizeAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    components: {
        DetailsAction,
        Feedback,
        Loading,
        ReauthorizeAction,
        RemoveAction,
        RemoveRecordModal,
        WitTable,
    },
    notifications: {
        authorizedSuccess: {
            type: VueNotifications.types.success,
            title: 'Authorized successfully',
        },
        authorizedError: {
            type: VueNotifications.types.error,
            title: 'Something went wrong',
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
    },
    mounted() {
        this.reloadTableInterval = setInterval(this.reloadTable, 5000)
    },
    destroyed() {
        clearInterval(this.reloadTableInterval)
    },
    data() {
        return {
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/oauth`,
            columns: ['owner', 'type', 'actions'],
            tokenForm: {
                type: '',
                scope: [],
                customForm: {},
            },
            showRemoveModal: false,
            detailsMode: false,
            removeRecordId: null,
            removeError: {
                state: null,
                message: null,
            },
        }
    },
    methods: {
        async requestFunction(params) {
            const response = await this.axios
                .get(this.apiUrl, {
                    params: {
                        ...params,
                        projectId: this.$store.state.project.active.id,
                    },
                })
                .catch(exception => console.error(exception))

            return {
                data: response.data.data,
                count: Infinity,
            }
        },

        showDetails(token) {
            this.tokenForm = {
                type: token.type,
                scope: token.token && token.token.scope ? token.token.scope.split(/[ ,]/) : undefined,
                customForm: {},
            }

            if (this.tokenForm.type === 'Awin') {
                this.tokenForm.customForm = {
                    token: token.token,
                }
            }

            if (this.tokenForm.type === 'Tradedoubler') {
                this.tokenForm.customForm = {
                    clientId: token.clientId,
                    clientSecret: token.clientSecret,
                    username: token.owner.name,
                }
            }

            if (this.tokenForm.type === 'Baselinker') {
                this.tokenForm.customForm = {
                    token: token.token,
                }
            }

            if (this.tokenForm.type === 'Shoper') {
                this.tokenForm.customForm = {
                    username: token.username,
                }
            }

            if (this.tokenForm.type === 'RTBHouse') {
                this.tokenForm.customForm = {
                    username: token.username,
                }
            }

            if (this.tokenForm.type === 'Optimise') {
                this.tokenForm.customForm = {
                    token: token.token,
                }
            }

            if (this.tokenForm.type === 'Magento') {
                this.tokenForm.customForm = {
                    accessToken: token.token.accessToken,
                    domain: token.token.domain,
                }
            }

            this.detailsMode = true
        },

        reauthorize(token) {
            try {
                this.$store.commit('loading/PROCESSING', `Reauthorizing...`)

                const options = {
                    scope: token.token.scope ? token.token.scope.split(/[ ,]/) : undefined,
                    projectId: this.activeProject.id,
                    userId: this.$auth.user().id,
                }

                this.$store
                    .dispatch(`${token.type.toLowerCase()}/upsertToken`, options)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.authorizedSuccess()
                        if (!document.hidden && this.$refs.tokenTable) this.$refs.tokenTable.fetchData({silent: true})
                    })
                    .catch(e => {
                        this.$store.commit('loading/PROCESSED')
                        this.authorizedError()
                    })
            } catch (e) {
                this.$store.commit('loading/PROCESSED')
                this.authorizedError()
            }
        },

        reloadTable() {
            if (!document.hidden && this.$refs.tokenTable) this.$refs.tokenTable.fetchData({silent: true})
        },

        removeRecord() {
            this.removeError.state = null
            this.removeError.message = null

            this.$store.commit('loading/PROCESSING', `Removing the resource...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/oauth/${this.removeRecordId}`)
                .then(response => {
                    this.showRemoveModal = false
                    this.$store.commit('loading/PROCESSED')
                    this.reloadTable()
                })
                .catch(exception => {
                    if (exception.response.status === 409) {
                        const resourcesToDelete = JSON.parse(exception.response.data.data)
                            .map(({name}) => name)
                            .join(', ')
                        this.removeError.message = `We cannot delete this resource. We have detected that it occurs on other resources. First, remove it from these places: ${resourcesToDelete}`
                    } else {
                        this.removeError.message = exception.response.data.data || 'Something went wrong'
                    }

                    this.removeError.state = false
                    this.$store.commit('loading/PROCESSED')
                })
        },

        removeModal(resource) {
            this.removeRecordId = resource.id
            this.removeError.state = null
            this.removeError.message = null
            this.showRemoveModal = true
        },
    },
}
</script>

<style lang="scss"></style>
